@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.NewsletterSignup';

#{$name} {
    color: $white;
    overflow: hidden;
    position: relative;
    width: 100%;

    &-image {
        position: absolute;
        width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    &-title {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 3rem;
        }
    }

    &-intro {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            padding: 0 15%;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 25%;
        }
    }

    &-termsConditionsLink {
        color: $white;
        text-decoration: underline;

        &:link, &:hover, &:active, &:visited {
            color: inherit;
        }
    }

    &-email {
        @include icon-envelope($gray-400);
        background-size: 22px 16px;
        background-position: 13px 50%;
        background-repeat: no-repeat;
        border: none;
        border-radius: 2rem;
        padding: 6px 0 6px 40px;
        font: 1.25rem $headings-font-family;
        display: block;
        width: 100%;
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
            display: inline-block;
            width: auto;
            margin-top: 0;
            margin-right: 1rem;

            &-label {
                display: none;
            }
        }
    }

    &-submit {
        display: block;
        width: 100%;
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
            display: inline;
            width: auto;
            margin-top: 0;
        }
    }

    &-loader {
        border-bottom-color: $white;
        border-top-color: $white;
    }

    .field-validation-error {

        span {
            background-color: $danger;
            color: $white;
            padding: .2rem;
        }
    }

    .custom-control.custom-checkbox {
        .field-validation-error {
            display: block;
        }
    }

    &-input {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

        .field-validation-error {
            order: 1;
            width: 100%;
            margin-top: .5rem;
        }
    }


    .modal {
        color: $black;
    }
}
